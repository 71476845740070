import React from 'react';
import PropTypes from 'prop-types';
import Avatar from './Avatar';

const Logo = ({ size }) => {
	return <Avatar src='/img/transparent_logo.png' size={size} />;
};

Logo.propTypes = {
	size: PropTypes.number,
};

Logo.defaultProps = {
	size: 50,
};

export default Logo;
